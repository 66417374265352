.skill {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;
    padding-left: 30px;
    gap: 2em;
    width: 20em;
    border-radius: 5px;
    height: 6em;
    background-color: rgb(53, 53, 52);
    color: white;
    font-family: 'Clash Display', sans-serif;
}

.skillMapped {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2em;
}

.icon {
    width: 2em;
    height: 2em;
    object-fit: contain;
}

#skillsHeading {
    margin-left: 2.3em;
    margin-top: 4em;
    margin-bottom: 8px;
    color: white;
    font-size: 2em;
    font-family: 'Clash Display', sans-serif;
}

.skillsLine {
    width: 90%;
    border: 1px solid white;
    height: 0.1px;
    margin-left: 4.5em;
    margin-right: 5em;
    margin-bottom: 50px;
}



@media screen and (max-width: 600px) { 
    #skillsHeading {
        margin-left: 1em;
        margin-top: 33em;
    }

    .skillsLine {
        margin-left: 1.5em;
    }
    
}