.Header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4em;
    gap: 40em;
    background-color: black;
    position: sticky;
    top: 0%;
    animation: fadeIn 2s ease-in-out;
}


@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

.Nav-Bar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

a {
    text-decoration: none;
  color: rgb(255, 255, 255);
  font-family: 'Clash Display', sans-serif;
  font-weight: 600;
}

a:hover{
border-bottom: 1px solid white;
}

button {
    border: none;
    width: 8em;
    height: 50px;
    cursor: pointer;
    font-family: 'Clash Display', sans-serif;
    font-weight: 600;
}

button:hover {
    border: 1px solid white;
  color: rgb(214, 212, 212);
    background: none;
}

@media screen and (max-width: 600px) { 
  .Header {
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-right: 3em;
    margin-left: -10em;
    width: 18em;
    height: 20em;
    gap: 3em;
    background-color: rgb(0, 0, 0);
    position: absolute;
    top: 0%;
    padding-top: 2em;
    left: 55%;
    animation: fadeIn 2s ease-in-out;
}

.Nav-Bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
}
}
  