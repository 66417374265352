@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

.video {
    width: 100%;
    position: relative;
    margin-top: -90px;
    z-index: -1;
    animation: fadeIn 1s ease-in-out;

}

.Hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: absolute;
    top: 5%;
    left: 25%;
    right: 25%;
    z-index: -1;
    animation: fadeIn 5s ease-in-out;
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }


.ImageDiv {
    border-radius: 100%;
    width: 9em;
    height: 9em;
    object-fit: cover;
    background-color: white;
    padding: 10px;
    margin-bottom: 2em;
    margin-top: 5em;
}

.Profile-Picture {
    border-radius: 100%;
    width: 9.5em;
    height: 10em;
    object-fit: cover;
}

.Intro {
    color: #d6d4d4;
    margin-bottom: -1px;
    font-size: 1.3em;
    font-family: 'Clash Display', sans-serif;
}

h2 {
    text-align: center;
    color: white;
    margin-top: 5px;
    font-family: 'Clash Display', sans-serif;
    font-size: 3.5em;
}

.Others {
    text-align: center;
    color: white;
    margin-top: 10px;
    font-family: 'Clash Display', sans-serif;
    font-size: 3.5em;
    margin-top: -1.2em;
}

.Intro-Text {
    color: white;
    font-family: 'Clash Display', sans-serif;
    text-align: center;
    font-size: 1.3em;
    width: 50em;
}

@media screen and (max-width: 600px) { 
    
.Hero {
  
    position: absolute;
    top: 15%;
}

    h2 {
        font-size: 2.3em;
    }

    .Others {
        text-align: center;
        color: white;
        margin-top: 6px;
        font-family: 'Clash Display', sans-serif;
        font-size: 2.4em;
        margin-top: -0.8em;
    }

    .Intro-Text {
        color: white;
        font-family: 'Clash Display', sans-serif;
        text-align: center;
        font-size: 1.5em;
        width: 12em;
        
    }
}