body {
  margin: 0;
  padding: 0%;
 background-color: black;
  background-attachment: fixed;
}

.card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2em;
  margin-top: 8em;
}
